// Variable overrides

$blue: #0098ef !default;
// $indigo:  #6610f2 !default;
$purple: #7b37d3 !default;
// $pink:    #e83e8c !default;
$red: #ee220c !default;
// $orange:  #fd7e14 !default;
// $yellow:  #ffc107 !default;
$green: #1db100 !default;
// $teal:    #20c997 !default;
// $cyan:    #17a2b8 !default;
$gray: #eaedf3 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'purple': $purple,
  ),
  $theme-colors
);

$largeDesktop: 1199.98px;
$desktop: 991.98px;
$tablet: 767.98px;
$phone: 575.98px;
