.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .main {
    display: flex;
    flex-grow: 1;
    & > div,
    & > section {
      width: 100%;
    }
  }
}
