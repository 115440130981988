@import './variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

@font-face {
  font-family: AvenirNext;
  font-weight: 400;
  src: url('./fonts/AvenirNext/avenir-next-regular.ttf') format('truetype');
}
@font-face {
  font-family: AvenirNext;
  font-weight: 500;
  src: url('./fonts/AvenirNext/avenir-next-medium.ttf') format('truetype');
}
@font-face {
  font-family: AvenirNext;
  font-weight: 600;
  src: url('./fonts/AvenirNext/avenir-next-demi.ttf') format('truetype');
}
@font-face {
  font-family: AvenirNext;
  font-weight: 700;
  src: url('./fonts/AvenirNext/avenir-next-bold.ttf') format('truetype');
}

@font-face {
  font-family: Flexo;
  font-weight: 400;
  src: url('./fonts/Flexo/Flexo-Regular.ttf') format('truetype');
}
@font-face {
  font-family: Flexo;
  font-weight: 500;
  src: url('./fonts/Flexo/Flexo-Medium.ttf') format('truetype');
}
@font-face {
  font-family: Flexo;
  font-weight: 600;
  src: url('./fonts/Flexo/Flexo-Demi.ttf') format('truetype');
}
@font-face {
  font-family: Flexo;
  font-weight: 700;
  src: url('./fonts/Flexo/Flexo-Bold.ttf') format('truetype');
}
@font-face {
  font-family: Flexo;
  font-weight: 800;
  src: url('./fonts/Flexo/Flexo-Heavy.ttf') format('truetype');
}
@font-face {
  font-family: Flexo;
  font-weight: 900;
  src: url('./fonts/Flexo/Flexo-Black.ttf') format('truetype');
}
@import './reactAddToCalander';
@import './style';
@import './button';
@import './header';
@import './form';
@import './modal';
@import './call';
@import './eventCalander';
@import './loader';
@import './animation';
@import './react-star';
