body {
  background: #fafafa;
  font-family: AvenirNext;
}
h1,
h2 {
  font-family: 'Flexo';
  &.title {
    font-weight: 700;
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 10px;
}
.no-scrollBar {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.bg-black {
  background: #000;
}

.event-options-screen {
  display: flex;
  align-items: center;

  justify-content: center;
  .c-col {
    text-align: center;
    @media (min-width: 400px) {
      padding: 0 30px;
    }
    button {
      font-size: 22px;
    }
  }
}
.events-wrapper {
  padding-top: 24px;
  .head {
    .imageBox {
      img {
        max-width: 65px;
        max-height: 65px;
      }
    }
  }
  .imageBox {
    width: 100px;
    height: 100px;
    img {
      max-width: 90px;
      max-height: 90px;
    }
  }
  .gifWrapper {
    max-width: 300px;
    margin: 0 auto;
  }
  .main-text {
    font-size: 18px;
    text-align: center;
    padding: 0;
    margin-bottom: 8px;
    svg {
      font-size: 1.3em;
      color: $purple;
    }
  }
  p {
    padding-right: 8px;
    font-size: 18px;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .events-list {
    background: #d5d5d5;
    padding: 32px 25px 16px;
    border-radius: 60px;
    .view {
      max-height: 362px;
      overflow: auto;
      margin-top: -16px;
      .column {
        @media (max-width: 420px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
        padding-top: 16px;
        button {
          max-width: 100%;
        }
      }
    }
  }
}

.notificationsWrapper {
  display: flex;
  justify-content: center;
  .person-img {
    object-fit: cover;
    background: #fff;
    box-shadow: 0 2px 5pt 2pt rgba(102, 102, 102, 0.5);
    border-radius: 100%;
    width: 60px;
    height: 60px;
    @media (max-width: $desktop) {
      width: 52px;
      height: 52px;
    }
    @media (max-width: $tablet) {
      width: 45px;
      height: 45px;
    }
    @media (max-width: $phone) {
      width: 40px;
      height: 40px;
    }
  }
  p {
    font-size: 20px;
    margin-left: 30px;
    margin-bottom: 0;
    @media (max-width: $desktop) {
      font-size: 18px;
    }
    @media (max-width: $tablet) {
      font-size: 16px;
    }
    @media (max-width: $phone) {
      font-size: 14px;
      margin-left: 16px;
    }
  }
  .btn-icon {
    &.tick {
      color: $green;
      &:hover {
        color: darken($green, 10%);
      }
    }
    &.cancel {
      color: $red;
      &:hover {
        color: darken($red, 10%);
      }
    }
    svg {
      font-size: 40px;
    }
  }
  .accepted {
    line-height: initial;
    margin-top: 5px;
  }
}
.eventDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 400px;
  }
  .eventIcon {
    margin-right: 35px;
    img {
      width: 40px;
    }
  }
  .inputwrapper {
    position: relative;
    input {
      min-width: 250px;
    }
  }
  .eventDetailDropdown {
    background: #fff;
    border-radius: 20px;
    padding: 30px 15px 15px;
    min-height: 130px;
    width: 270px;
    margin-top: 10px;
    position: absolute;
    top: 100%;
    z-index: 2;
    max-width: 100%;
    .eventscroll {
      padding-top: 4px;
      padding-left: 7px;
      margin-left: -7px;
      max-height: 170px;
      overflow: auto;
    }
  }

  .date {
    white-space: nowrap;
    color: #fff;
    font-size: 24px;
  }
  .eventsInputs {
    display: flex;
    align-items: center;
    position: relative;
    .uploadedImg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: 100%;
      max-width: 200px;
      overflow: auto;
      img {
        box-shadow: none;
        margin-left: 10px;
      }
      // .locationImg {
      //   margin: 0 11px;
      // }
    }
    .upload-icon {
      display: flex;
      justify-content: space-between;
      position: relative;
      i {
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }
}

.userImg {
  background: #fff;
  box-shadow: 0 2px 5pt 2pt rgba(102, 102, 102, 0.5);
  border-radius: 100%;

  display: block;
  width: 42px !important;
  flex: 0 0 42px;
  height: 42px;
}
.locationImg {
  background: #00a2ff;
  border-radius: 8px;
  width: 42px;
  height: 42px;
}

.eventDetailList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .content {
    flex: 0 0 calc(100% - 40px);
    max-width: calc(100% - 40px);
  }
  .btb-icon {
    flex: 0 0 40px;
    max-width: 40px;
    color: #929292;
    padding: 0;
    svg {
      width: 40px;
      height: 40px;
    }
    &.active {
      color: #1db100;
    }
  }
}

.datepickerFill {
  position: relative;
  .react-datepicker-wrapper,
  .rdt {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    input {
      opacity: 0;
      width: 100%;
    }
  }
}
.rdtPicker {
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 #8b8b8b;
  .rdtSwitch,
  .dow {
    font-weight: 600;
  }
  td {
    &.rdtDay {
      border-radius: 4px;
    }
    &.rdtActive {
    }
    &.rdtActive,
    &.rdtActive:hover {
      background-color: $blue;
    }
  }
}
.rdtCounter {
  height: 80px;
  &Separator {
    line-height: 82px;
  }
  .rdtCount {
    height: 30px;
    height: 24px;
    font-size: 16px;
  }
  .rdtBtn {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
  }
}

.welcome {
  display: flex;
  align-items: center;
  .gifWrapper {
    max-width: 500px;
    margin: 0 auto 10px;
    @media (max-width: 1440px) {
      max-width: 420px;
    }
    @media (max-width: $desktop) {
      max-width: 360px;
    }
  }
  &.feedback-view {
    .gifWrapper {
      min-height: 435px;
      @media (max-width: 1440px) {
        max-width: 360px;
        min-height: 313px;
      }
   
    }
  }
  .main-text {
    font-size: 36px;
    margin-bottom: 20px;
    @media (max-width: $largeDesktop) {
      font-size: 28px;
    }
    @media (max-width: $desktop) {
      font-size: 24px;
    }
    svg {
      font-size: 1.3em;
      color: $purple;
    }
  }
  .feedbackTitle {
    font-size: 30px;
    letter-spacing: -1.5px;
    margin-bottom: 4px;
    @media (max-width: 1440px) {
      font-size: 26px;
    }
  }
  .feedback-form-item {
    max-width: 400px;
    margin: auto;
    @media (max-width: 1440px) {
      max-width: 372px;
    }
    .form-control {
      border-radius: 20px;
      height: 144px;

      border: 2px solid $purple;
      resize: none;
      color: $purple;
      font-size: 22px;
      @media (max-width: 1440px) {
        height: 100px;
        font-size: 18px;
      }
      &::placeholder {
        color: lighten($purple, 30%);
      }
    }
  }
  .btn {
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 18px;
    @media (max-width: $largeDesktop) {
      font-size: 18px;
    }
    @media (max-width: $desktop) {
      font-size: 16px;
    }
    &.feedback {
      font-size: 28px;
      padding: 2px 12px;
      @media (max-width: 1440px) {
        font-size: 24px;
      }
    }
  }
}
// .image-width {
//   width: 15%;
// }
.link-border {
  border: 2px solid #d5d5d5;
  overflow: hidden;
  width: fit-content;
  margin: 0 auto;
  p {
    font-size: 14px;
    width: 225px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  div {
    background: transparent;
    border: 0;
    margin-left: 22px;
    padding: 0;
  }
  &.active {
    cursor: pointer;
  }
}
.emoji-container {
  padding: 50px;
}
.display-flex {
  display: flex;
}
.icon {
  size: 10em;
}
.chain-button {
  background: transparent;
  border: 0;
  img {
    width: 40px;
  }
}
.padding-left-15 {
  padding-left: 15px;
}
