.form-control {
  &:focus {
    box-shadow: none;
  }
}
.rounded-input {
  .form-control {
    border-radius: 100px;
    border-color: #000;
  }
}

.main-form-wrapper {
  max-width: 430px;
  margin: auto;
  // min-height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  h2 {
    font-size: 40px;
  }
  p {
    font-weight: 500;
    &.linkText {
      font-size: 20px;
      letter-spacing: -1px;
      a {
        font-weight: 600;
      }
    }
    &.sign-up-link {
      font-size: 20px;
      letter-spacing: -1px;
      span {
        cursor: pointer;
        font-weight: 600;
        color: #0098ef;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .subText {
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 10px;
  }
  .f-login {
    font-weight: 600;
    i {
      font-size: 22px;
    }
  }
  .btn {
    border-radius: 0.25rem;
    &.disable {
      background: rgba(#0098ef, 0.5);
      border-color: rgba(#0098ef, 0.1);
    }
  }
  .or {
    text-align: center;
    position: relative;
    font-weight: 600;
    color: #959595;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: rgba(#959595, 0.4);
    }
    span {
      position: relative;
      background: #fff;
      padding: 0 22px;
    }
  }
  .form-control {
    background: #fafafa;
  }
  .forgot-pass {
    a {
      color: #1e507a;
      font-weight: 600;
    }
  }
}

.event-create-form {
  .inputRow {
    display: flex;
    .photo {
      width: 150px;
    }
    .form-group {
      margin-bottom: 0;
    }
    .formWrapper {
      flex-grow: 1;
    }
  }
  p {
    font-size: 18px;
    margin-left: 15px;
    font-weight: 500;
  }
}
.login-form {
  .f-login {
    background: transparent;
    border: 0;
    color: #1e507a;
    font-size: 18px;
  }
}

.contactModla {
  .modal-close {
    top: 16px;
    right: 16px;
    img {
      width: 12px;
      height: 12px;
    }
  }
}
.contactForm {
  padding: 12px;
  .content {
    margin-bottom: 38px;
    h3 {
    }
    p {
      color: #909aa3;
      color: #909aa3;
      margin-bottom: 4px;
      font-weight: 500;
    }
  }
  .form-group {
    position: relative;
    margin-bottom: 26px;
    label {
      background: #fff;
      padding: 0 6px;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      position: absolute;
      top: 0;
      left: 6px;
      line-height: 1;
      transform: translateY(-50%);
    }
  }
  textarea {
    min-height: 90px;
  }
  .form-control {
  }
  .btn {
    border-radius: 6px;
  }
}
.settingForm {
  padding: 12px;
  .content {
    h3 {
    }
    p {
      color: #909aa3;
      color: #909aa3;
      margin-bottom: 4px;
      font-weight: 500;
    }
  }
  .form-group {
    position: relative;
    margin-bottom: 26px;
    label {
      background: #fff;
      padding: 0 6px;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      position: absolute;
      top: 0;
      left: 6px;
      line-height: 1;
      transform: translateY(-50%);
    }
  }
  textarea {
    min-height: 90px;
  }
  .form-control {
  }
  .btn {
    border-radius: 6px;
  }
}

.chat-timer {
  border: 2px solid $purple;
  padding: 2px 15px;
  background: #fff;
  .form-control {
    padding: 0;
    border: 0;
    height: 36px;
    font-size: 18px;
    border-bottom: 2px solid #525252;
    text-align: center;
    border-radius:0;
    background: #fff;
    &::placeholder {
      color: #cfcfcf
    }
  }
  .btn {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 14px;
    min-width: 70px;
  }
}
