.header {
  position: sticky;
  top: 0;
  left: 0;
  padding: 8px 0;
  background: #fff;
  border-bottom: 1px solid #eae9e8;
  z-index: 999;
  .logo {
    position: relative;

    img {
      height: 46px;
      @media (max-width: $tablet) {
        height: 42px;
      }
    }
  }
  .dropDownBtn {
    background: transparent !important;
    border: 0 !important;
    padding: 0;
    box-shadow: none !important;
  }
  .dropdown-menu {
    padding-top: 22px;
    padding-bottom: 16px;
    width: 220px;
    outline: none;
    .contact-us {
      text-decoration: underline;
      color: $blue;
      &:active {
        color: #fff;
      }
    }
    // .logout {
    //   width: initial;
    //   margin: auto;
    //   border: 1px solid #dadce0;
    //   border-radius: 4px;
    // }
    .profile {
      position: relative;
      display: table;
      margin: 0 auto 8px;
      &-name {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 4px;
        text-transform: capitalize;
        .nameWrapper {
        }
        input {
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 24px;
          font-size: 16px;
          font-weight: 600;
          width: calc(100% - 20px);
          margin: 0 auto 4px;
          outline: none !important;
        }
        .btn-icon {
          line-height: 1;
          &.edit {
            color: $gray-800;
            &:hover {
              color: lighten($gray-800, 10%);
            }
          }
          &.save {
            color: $green;
            &:hover {
              color: darken($green, 10%);
            }
          }
          &.cancle {
            color: $red;
            &:hover {
              color: darken($red, 10%);
            }
          }
        }
      }
      &-email {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 12px;
      }
      img {
        display: block;
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 100%;
      }
      .btn-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        line-height: 1;
        background: #fff;
        box-shadow: 0 3px 6px 0 #8b8b8b, 0 0 0 3px #fff;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        font-size: 12px;
      }
    }
  }
  .wrapper {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btnWrapper {
    display: flex;
    align-items: center;
    .camera {
      padding: 0;
      color: #000;
      background: transparent !important;
      border: 0 !important;
      position: relative;
      span {
        font-size: 10px;
        padding: 2px;
        min-width: 15px;
        display: block;
        text-align: center;
        color: #fff;
        line-height: 1;
        background: $red;
        position: absolute;
        border-radius: 100%;
        top: -5px;
        left: 100%;
        margin-left: -7px;
      }
      &:hover {
        color: lighten(#000, 25%);
      }
      svg {
        font-size: 32px;
        @media (max-width: $phone) {
          // font-size: 26px;
        }
      }
    }
    .events {
      color: #000;
      &:hover {
        color: lighten(#000, 10%);
      }
      i {
        font-size: 22px;
        @media (max-width: $phone) {
          // font-size: 18px;
        }
      }
    }
    .notifications {
      color: #445a63;
      &:hover {
        color: lighten(#445a63, 10%);
      }
      i {
        font-size: 22px;
        @media (max-width: $phone) {
          // font-size: 18px;
        }
      }

      position: relative;
      span {
        font-size: 10px;
        padding: 2px;
        min-width: 15px;
        display: block;
        text-align: center;
        color: #fff;
        line-height: 1;
        background: $red;
        position: absolute;
        border-radius: 100%;
        top: -5px;
        left: 100%;
        margin-left: -7px;
      }
    }
  }
  .avatar {
    img {
      display: block;
      width: 42px;
      height: 42px;
      object-fit: cover;
      border-radius: 100%;
    }
  }
  .searchBtn {
    font-size: 22px;
    margin-right: 4px;
  }
  .invitefriendsWrapper {
    .lds-roller {
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.7);
      div:after {
        background: #000;
      }
    }
    @media (max-width: $phone) {
      &:not(.active) {
        display: none;
      }
      &.active {
        padding: 0 15px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1;
        background: #fff;
        display: flex;
        align-items: center;
        & > div {
          width: 100%;
        }
      }
    }
  }

  .invitefriends {
    width: 250px;
    position: relative;
    @media (max-width: $phone) {
      width: 100%;
    }
    .searchResults {
      position: absolute;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 30px;
      background: #fff;
      top: calc(100% + 22px);
      box-shadow: 0 2px 5pt 2pt rgba(102, 102, 102, 0.12);
      .scrollView {
        padding: 10px 10px;
        max-height: calc(100vh - 173px);
        overflow: auto;
      }
      .person-image {
        background: #fff;
        box-shadow: 0 1px 5px 4px #cccc;
        border-radius: 100%;
        width: 42px !important;
        flex: 0 0 42px;
        height: 42px;
        object-fit: cover;
        margin-right: 8px;
      }
      p {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.2;
        word-break: break-all;
        padding-right: 4px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .personConnect {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:not(:first-child) {
          margin-top: 16px;
        }
        .btn {
          line-height: 1;
        }
      }
    }
    .form-control {
      padding-right: 42px;
    }
    .srch-icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      .sendBtn {
        padding: 0;
        padding-right: 15px;
        &:hover {
          img {
            opacity: 0.7;
          }
        }
        img {
          transition: 0.3s;
          height: 25px;
        }
        i {
          color: #8e8585;
        }
      }
    }
  }
}
