.modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: 0;
  padding: 4px;
  line-height: 1;
  img {
    width: 22px;
    height: 22px;
  }
}
.modal-content {
  box-shadow: 0 3px 6px 0 #8b8b8b;
}

.add-photo-modal {
  .modal-content {
    border-radius: 0;
  }
  .modal-body {
    padding: 70px 50px;
    min-height: calc(100vh - 182px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @media (max-height: 700px) {
      padding-top: 50px;
      padding-bottom: 35px;
      min-height: calc(100vh - 100px);
    }
  }
  .title {
    font-weight: 700;
  }
  .submitBtn {
    padding: 12px 0;
    text-transform: uppercase;
    font-weight: 500;
    &.disable {
      background: rgba(#6c757d, 0.3);
      border-color: rgba(#6c757d, 0.1);
    }
  }
  .text {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
  }
  .imapeWrapper {
    position: relative;
    img {
      display: block;
      width: 110px;
      height: 110px;
      border-radius: 100%;
      object-fit: cover;
    }
    .file-input-label {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 0;
        line-height: 1;
        background: #fff;
        box-shadow: 0 3px 6px 0 #8b8b8b, 0 0 0 3px #fff;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        font-size: 12px;
      }
      input[type="file"] {
        display: none;
      }
    }
  }
}
.delete-modal {
  .modal-body {
    padding: 40px 30px;
    .modal-close {
      top: 16px;
      right: 16px;
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
  .m-text {
    font-size: 18px;
    span {
      font-weight: 600;
      font-style: italic;
    }
  }
}
.eventModal {
  margin: {
    left: auto;
    right: auto;
  }
  padding: {
    left: 12px;
    right: 12px;
  }
  max-width: 442px;
  .modal-body {
    padding: 32px;
    .modal-close {
      top: 16px;
      right: 16px;
      img {
        width: 12px;
        height: 12px;
      }
    }
    .btn-icon {
      line-height: 1;
      color: #929292;
      font-size: 42px;
      display: flex;
      align-items: center;
      cursor: default;
      // &:hover {
      //   color: darken(#929292, 10%);
      // }
      &.active {
        color: #1db100;
        // &:hover {
        //   color: darken(#1db100, 10%);
        // }
      }
    }
    .title {
      margin-bottom: 22px;
    }
    .time-date {
      color: #898b8d;
      font-size: 13px;
      font-weight: 600;
    }
    .b-title {
      font-size: 18px;
      font-weight: 600;
    }
    .eventWrapper {
      max-height: 200px;
      overflow: auto;
    }
    .eventName {
      font-size: 18px;
      font-weight: 500;
      color: #7b8492;
      display: flex;
      line-height: 1;
      align-items: center;
      text-transform: capitalize;
      max-width: 220px;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
      text-align: left;
      &:hover {
        text-decoration: underline;
      }
      img {
        width: 40px;
        height: 40px;
        box-shadow: 0 3px 6px 0 #8b8b8b;
        border-radius: 6px;
        object-fit: contain;
        background: #fff;
        margin-right: 16px;
      }
    }
    .peronsOption {
      margin: 0 auto 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 220px;
      .person-image {
        background: #fff;
        box-shadow: 0 2px 5pt 2pt rgba(102, 102, 102, 0.5);
        border-radius: 100%;
        width: 42px;
        height: 42px;
        object-fit: cover;
        margin-right: 16px;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        word-break: break-all;
        padding-right: 8px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .modal-footer {
    color: #969899;
    font-weight: 600;
    font-size: 14px;
    p {
    }
    button {
      background: transparent;
      border: 0;
      line-height: 1;
      font-weight: 600;
      color: #969899;
      &:hover,
      &.active {
        color: $blue;
      }
    }
  }
}

.fixedModal {
  position: fixed;
  z-index: 1000;
  right: 30px;
  top: 86px;
  background: #fff;
  width: 300px;
  padding: 30px;
  border-radius: 30px;
  box-shadow: 0 3px 6px 0 #8b8b8b;
  &.notification-modal,
  &.connect-friendModal {
    padding: 20px 15px;
  }
  &.incommingCall,
  &.connect-friendModal {
    position: fixed !important;
    transform: initial !important;
    top: 86px !important;
    right: 30px !important;
    left: initial !important;
    outline: none;
    z-index: 1000;
  }
  &.incommingCall {
    border-radius: 16px;
    width: 300px;
    padding: 30px !important;
    .srollView {
      max-height: calc(100vh - 300px);
      overflow: auto;
      padding-top: 12px;
    }
    .person {
      flex-direction: column;
      margin-bottom: 8px;
      img {
        margin-right: 0;
        margin-bottom: 8px;
        width: 80px !important;
        height: 80px;
      }
    }
  }
  &.connect-friendModal {
    max-width: 270px;
    .srollView {
      max-height: calc(100vh - 300px);
      overflow: auto;
      padding-top: 12px;
      padding-left: 7px;
      margin-left: -7px;
    }
  }
  &.notification-modal {
    .notify {
      display: flex;
      align-items: center;
      display: flex;
      width: 100%;
      margin: 0 auto 22px;
      p {
        margin: 0;
        font-size: 16px;
      }
    }
  }
  .person-image {
    display: block;
    background: #fff;
    box-shadow: 0 2px 5pt 2pt rgba(102, 102, 102, 0.5);
    border-radius: 100%;
    width: 50px !important;
    height: 50px;
    object-fit: cover;
    margin-right: 16px;
  }
  .person {
    display: flex;
    align-items: center;
    display: flex;
    width: 100%;
    max-width: 150px;
    margin: 0 auto 22px;
    .person-image {
      margin-right: 30px;
    }
    p {
      margin: 0;
      font-size: 20px;

      &:not(.normal) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .callOption {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 150px;
    margin: auto;
  }
  .callBtn {
    padding: 0;
    // flex: 0 0 65px;
    // max-width: 65px;
    font-size: 45px;
    line-height: 1;
    &.accept {
      color: $blue;
      &:hover {
        color: lighten($blue, 10%);
      }
    }
    &.reject {
      color: $red;
      &:hover {
        color: lighten($red, 10%);
      }
      .call-icon {
        transform: rotate(135deg);
        transform-origin: 50% 50%;
      }
    }
    img {
      border-radius: 100%;
      width: 45px;
      height: 45px;
    }
  }
  .sm-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .personConnect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .call-content {
      // flex: 0 0 calc(100% - 65px);
      // max-width: calc(100% - 65px);
      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 98px;
      }
    }
  }
}
.iconSelection {
  @media (min-width: $tablet) {
    max-width: 530px;
  }
  .modal-content {
    border-radius: 0;
  }
  .modal-body {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .form-control {
    border-radius: 0;
  }
  .title {
    font-size: 2rem;
    font-weight: 400;
  }
  .dec {
    margin-bottom: 34px;
    p {
      margin-bottom: 0;
    }
  }
  .iconsListWrapper {
    max-width: 330px;
    .password-icon {
      cursor: pointer;
    }
  }
  .iconsList {
    margin-left: -8px;
    margin-right: -8px;
    > div {
      padding-left: 8px;
      padding-right: 8px;
    }
    img {
      max-width: 80px;
    }
  }
  .selectedIconsWrapper {
    max-width: 210px;
  }
  .selectedIcons {
    display: flex;
    align-items: center;
    .pass {
      font-size: 1.8rem;
      margin-bottom: 0;
      margin-right: 12px;
    }
    img {
      height: 40px;
    }
    .password-icon-wrapper {
      display: flex;
      justify-content: space-between;
      position: relative;
      padding-right: 8px;
      margin-right: 4px;
      i {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        font-size: 14px;
        color: gray;
      }
    }
  }
  .join {
    max-width: 300px;
    margin: 0 auto;
    .btn {
      font-weight: 500;
      min-width: 150px;
      height: 47px;
      font-size: 20px;
      box-shadow: 0 3px 6px 2px #8b8b8b, 0 0 0 3px #fff;
    }
  }
}
