.animationScreen,
.animationTable {
  .fileUpload {
    z-index: 2;
    position: absolute;
    top: 6px;
    right: 6px;

    line-height: 1;
    background: #fff;
    box-shadow: 0 3px 6px 0 #8b8b8b, 0 0 0 3px #fff;
    width: 24px;
    height: 24px;

    border-radius: 100%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      &::before {
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        position: absolute;
      }
    }
    &:hover {
      box-shadow: 0 3px 6px 2px #8b8b8b, 0 0 0 3px #fff;
    }
    input[type="file"] {
      z-index: -1;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
.animationScreen {
  .name {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
  }
  .imgWrapper {
    position: relative;
    width: 100%;
    padding-top: 100%;
    background: #fff;
    margin-bottom: 16px;
  }
  .addAnimation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .callAniEdit {
    .form-control {
      padding-top: 0;
      padding-bottom: 0;
      height: 32px;
    }
    .btn {
      min-width: 70px;
    }
  }

  .fileOption {
    position: absolute;
    top: 6px;
    right: 6px;
    line-height: 1;
    z-index: 2;

    button {
      background: transparent;
      padding: 0;
      line-height: 1;
      border: 0;
      top: 6px;
      right: 6px;
      border-radius: 100%;
      line-height: 1;
      background: #fff;
      box-shadow: 0 3px 6px 0 #8b8b8b, 0 0 0 3px #fff;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      font-size: 12px;
      margin-bottom: 8px;
      display: block;
      &:hover {
        box-shadow: 0 3px 6px 2px #8b8b8b, 0 0 0 3px #fff;
      }
      .fa-check {
        color: $green;
      }
      .fa-times {
        color: $red;
      }
      &:hover {
        .fa-check {
          color: darken($green, 10%);
        }
        .fa-times {
          color: darken($red, 10%);
        }
      }
    }
  }
  img {
    object-fit: contain;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.animationTable {
  .title {
    font-size: 22px;
  }
  .addNewBtn {
    box-shadow: none !important;
    line-height: 1;
    padding: 0;
    width: 24px;
    height: 24px;
    font-size: 12px;

    i {
      transition: 0.3s;
    }
  }
  .table {
    .btn {
      min-width: 70px;
    }
    tbody {
      td {
        vertical-align: middle;
      }
    }
  }
  .keywordTd,
  .durationTd {
    width: 240px;
  }
  .durationT,
  .durationTd {
    text-align: center;
  }

  .animationImage {
    $size: 80px;
    position: relative;
    width: $size;
    height: $size;
    background: rgba(#000, 0.02);
    img {
      width: $size;
      height: $size;
      object-fit: contain;
    }
  }
}
