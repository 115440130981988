.call-layout {
  $chatScreenWidth: 250px;
  display: flex;
  flex-wrap: wrap;
  .video-screen {
    flex: 0 0 calc(100% - #{$chatScreenWidth});
    max-width: calc(100% - #{$chatScreenWidth});
  }

  .chat-screen {
    flex: 0 0 $chatScreenWidth;
    max-width: $chatScreenWidth;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 63px);
    $topBtnHeight: 78px;
    .top-btn {
      height: 78px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .light-theme & {
        background: #fff;
      }
      .tBtn {
        margin: 0 20px;
        transition: 0.3s;
        color: #fff;
        .light-theme & {
          color: #000;
        }
        &:hover {
          opacity: 0.7;
        }
        svg {
          height: 35px;
        }
        img {
          height: 35px;
        }
      }
    }
    .chat-box {
      height: calc(100% - #{$topBtnHeight});
      padding-bottom: 15px;
      .light-theme & {
        background: #fff;
      }
      .chat-area {
        height: calc(100% - 38px);
        padding-bottom: 15px;
        .chat-view-wrapper {
          padding: 8px;
          .light-theme & {
            margin: 2px;
            border: 2px solid #5b5b5b;
          }
        }
        .chat-view {
          height: 100%;
          overflow: auto;
          &.isTimer {
            height: calc(100% - 79px);
          }
        }
      }
      .inputWrapper {
        position: relative;
        .form-control {
          // padding-right: 42px;
          padding-right: 38px;
        }
        .smileBtn {
          position: absolute;
          top: 50%;
          right: 2px;
          padding: 0;
          transform: translateY(-50%);
          img {
            height: 30px;
          }
        }
        .sendBtn {
          padding: 0;
          padding-right: 15px;
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          &:hover {
            img {
              opacity: 0.7;
            }
          }
          img {
            transition: 0.3s;
            height: 28px;
          }
        }
      }
    }
  }
}
.callEndMsg {
  position: absolute;
  z-index: 999;
  background: rgba(#7b37d3, 0.9);
  color: #fff;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
  border-radius: 10px;
  padding: 10px 30px;
}
.screenShareBar {
  position: fixed;
  bottom: 0;
  z-index: 999;
  background: rgba(#7b37d3, 0.9);
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 20px 20px 0 0;
  padding: 10px 30px;

  &.video-screen {
    margin-left: -125px;
  }
  .shareContent {
    display: flex;
    justify-content: center;
    align-items: center;
    h4 {
      font-weight: normal;
      letter-spacing: -1px;
    }
    button {
      margin-left: 22px;
      img {
        width: 38px;
      }

      &.shareBtn {
        background: none !important;
        border: 0 !important;
        box-shadow: none !important;
        padding: 0;
      }
    }
  }
}
.msg-box {
  &:not(:first-child) {
    margin-top: 15px;
  }
  .chat-msgbox {
    border-radius: 6px;
    padding: 10px 20px;
    color: #fff;
    background: $blue;
    width: calc(100% - 20px);
    border-radius: 0 20px 20px 20px;
  }

  .chat-msgbox {
    &.user {
      &-1 {
        background: $blue;
      }
      &-2 {
        background: #9c27b0;
      }
      &-3 {
        background: #f57f17;
      }
      &-4 {
        background: #00bcd4;
      }
      &-5 {
        background: $red;
      }
      &-6 {
        background: #26a69a;
      }
      &-7 {
        background: #e91e63;
      }
      &-8 {
        background: #673ab7;
      }
      &-9 {
        background: #9e9d24;
      }
      &-10 {
        background: #0097a7;
      }
    }
    &:not(.me) {
      a {
        color: #fff;
      }
    }
    &.me {
      background: $gray;
      border-radius: 20px 20px 0 20px;
      margin-left: auto;
      .light-theme & {
        background: #929292;
      }
      .person {
        color: #000;
      }
      .msg {
        color: #9b9ea5;
      }

      .time {
        color: #9b9ea5;
      }
      .time,
      .msg {
        .light-theme & {
          color: #d5d5d5;
        }
      }
    }
  }
  .person {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    font-size: 16px;

    margin-bottom: 0;
    text-transform: capitalize;
  }
  .msg {
    word-break: break-word;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 0;
    // color: rgba(255, 255, 255, 0.6);
    color: #45515b;
  }
  .time {
    font-size: 13px;
    margin-bottom: 0;
    text-align: right;
    color: rgba(255, 255, 255, 0.6);
    line-height: 1;
  }
}

.video-view {
  $linkGap: 30px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .cheers {
    max-width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    &.disconnect {
      max-width: 300px;
    }
    p {
      text-align: center;
      background: rgba($purple, 0.3);
      border-radius: 12px;
      padding: 16px 8px;
      margin-top: 16px;
      margin-left: auto;
      margin-right: auto;
      font-size: 20px;
      max-width: 252px;

      color: #fff;
      font-weight: 600;
    }
    img {
      width: 100%;
    }
  }
  .contdown {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    color: #fff;
    p {
      font-size: 120px;
      margin: 0;
    }
  }
  .recorderState {
    display: flex;
    flex: 1;
    top: 100px;
    z-index: 9999;
    position: fixed;
    justify-content: center;
    align-items: flex-end;
    color: white;
    background-color: rgba(94, 94, 94, 0.61);
    width: 200px;
    height: 50px;
  }
  &-container {
    line-height: 1;
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    height: calc(100vh - 127px + #{$linkGap});
    padding-bottom: $linkGap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // overflow: auto;
    z-index: 10;

    .disabled-audio {
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index: 3;
      color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      &::before {
        content: "";
        position: absolute;
        height: 2px;
        width: calc(100% + 10px);
        background: $red;
        transform: rotate(-45deg);
      }
    }
    .lds-roller {
      z-index: 4;
      flex: initial;
      position: absolute;
      padding: 0;
      width: 64px;
      height: 64px !important;
      max-width: initial;
      > div {
        padding: 0 !important;
        height: auto;
      }
    }

    .screenShareViewWrapper {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: calc(100% - #{$linkGap} + 2px);
      // background-color: #000;
    }
    #screenShareView {
      position: absolute;
      top: 0px;
      left: 0px;
      width: calc(100% - 246px);
      height: 100%;
      z-index: 3;
    }
    .screenShareViewCapture {
      position: absolute;
      top: 0px;
      left: 0px;
      background: {
        color: #000;
        size: contain;
        position: center;
        repeat: no-repeat;
      }
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
      height: 100% !important;
      z-index: 2;
    }
    .shareText {
      background: rgba($purple, 0.9);
      color: #fff;
      position: absolute;
      z-index: 6;
      padding: 30px;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 6px;
    }
    &.activeScreen {
      // > * {
      //   filter: blur(20px);
      // }
      // #screenShareView {
      //   filter: none !important;
      // }
    }
    .c-logo {
      position: absolute;
      bottom: 42px;
      right: 10px;
      z-index: 4;
      background: rgba(#fff, 0.65);
      padding: 6px 11px 9px;
      border-radius: 12px;
      height: initial !important;
      img {
        height: 26px;
      }
    }
    .c-link {
      position: absolute;
      bottom: 0px;
      left: 0px;
      z-index: 1;
      background: #fff;
      padding: 0 4px;
      width: 100%;
      color: $purple;
      margin: 0;
      height: $linkGap;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    video {
      position: relative;
      z-index: 2;
    }
    .firstVideo {
      video {
        transform: scaleX(-1);
      }
      .cap-image {
        transform: scaleX(-1);
      }
    }
    .videosWrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      &.isSideView {
        position: absolute;
        right: 10px;
        width: 232px;
        top: 10px;
        height: calc(100vh - 144px);
        align-items: baseline;
        display: block;
        overflow: auto;
        > div {
          height: 172px !important;
          flex: 0 0 100%;
          max-width: 100%;
          width: 100%;
          > div {
            padding: 0 !important;
            height: 100% !important;
          }
        }
      }
      > div {
        > div {
          position: relative;
          .cap-image {
            object-fit: contain;
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: {
              size: cover;
              position: center;
            }
          }
        }
      }
    }
    &.length {
      &-1 {
        .videosWrapper {
          > div {
            flex: 0 0 100%;
            max-width: 100%;
            height: 100%;
          }
        }
      }

      &-2 {
        .videosWrapper {
          > div {
            height: initial;
          }
        }
      }
      &-2 {
        .videosWrapper {
          > div {
            > div {
              height: initial;
              @media (min-width: $tablet) {
                position: relative;
                padding-top: 80%;
                video {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                }
              }
            }
            @media (min-width: $tablet) and( max-height: 477px) {
              height: 100%;
              > div {
                padding-top: 0;
                height: 100%;
              }
            }
            @media (max-width: $tablet) {
              flex: 0 0 100%;
              max-width: 100%;

              height: 50%;
              > div {
                height: 100%;
              }
            }
          }
        }
      }
      &-3,
      &-4 {
        // @media (min-width: $tablet) {
        .videosWrapper {
          > div {
            height: 50%;
          }
        }
        // }
      }
      &-5,
      &-6 {
        .videosWrapper {
          > div {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            height: 50%;
            @media (max-width: $tablet) {
              height: 33.33%;
              flex: 0 0 50%;
              max-width: 50%;
            }
          }
        }
      }
      &-7,
      &-8,
      &-9 {
        .videosWrapper {
          > div {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            height: 33.33%;
            @media (max-width: $tablet) {
              height: 33.33%;
              flex: 0 0 33.33%;
              max-width: 33.33%;
            }
          }
        }
      }
    }

    .videosWrapper {
      > div {
        padding: 2px;
        flex: 0 0 50%;
        max-width: 50%;

        // @media (max-width: $tablet) {
        //   flex: 0 0 100%;
        //   max-width: 100%;
        // }
        > div {
          height: 100%;
        }
        video {
          background: rgba(#fff, 0.15);
          height: 100%;
          width: 100%;
          // min-height: 250px;
          // object-fit: contain;
          object-fit: cover;
        }
      }
    }

    .time-count {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      color: #ee220c;
      background: rgba(#fff, 0.5);
      margin: 0;
      min-width: 80px;
      text-align: center;
      padding: 4px;
      min-height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
      border-radius: 0 0 16px 16px;
    }
  }
  .btnWrapper {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 4px 12px;
    transform: translateY(-3px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -$linkGap;

    position: relative;
    background: #000;
    z-index: 999;
    .light-theme & {
      background: #fff;
    }
  }
  .cameraBtn {
    line-height: 1;
    background: transparent;
    border: 0;
    padding: 0;
    color: #fff;
    font-size: 30px;
    &.disable {
      opacity: 0.5;
      cursor: no-drop;
    }
  }
  .recorderBtn {
    cursor: pointer;
    &.disable {
      opacity: 0.5;
      cursor: no-drop;
    }
  }
  .btn-icon {
    color: #fff;
    .light-theme & {
      color: #5d5e5d;
    }
    &.upload-btn-disable {
      cursor: not-allowed !important;
      &:hover {
        background: transparent;
      }
      svg {
        opacity: 0.7;
      }
    }
  }
  .centerIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    // .call-btn-disable {
    //   opacity: 0.7;
    // }
    .btn-icon {
      $boxShadow: inset 0 0 0 1px #fff;
      padding: 0;
      border-radius: 100%;
      -moz-box-shadow: $boxShadow;
      -webkit-box-shadow: $boxShadow;
      box-shadow: $boxShadow;
      width: 50px;
      height: 50px;
      line-height: 1;
      margin: 0 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: rgba(#fff, 0.3);
      }
      &.disable,
      &.disabled {
        position: relative;
        svg {
          opacity: 0.7;
        }
        &::before {
          content: "";
          position: absolute;
          height: 4px;
          background: #fff;
          width: calc(100% - 14px);
          transform: rotate(-45deg);
          border-radius: 10px;
          z-index: 1;
        }
      }
      .light-theme & {
        $boxShadow: inset 0 0 0 1px #5d5e5d;
        -moz-box-shadow: $boxShadow;
        -webkit-box-shadow: $boxShadow;
        box-shadow: $boxShadow;
        &:hover {
          background: rgba(#5d5e5d, 0.3);
        }
        &.disable,
        &.disabled {
          &::before {
            background: #5d5e5d;
          }
        }
      }

      &.call-btn-disable {
        cursor: not-allowed;
        &:hover {
          background: transparent;
        }
        svg {
          opacity: 0.7;
        }
      }
    }
    .mic {
      font-size: 28px;
    }
    .call {
      font-size: 46px;
      .call-icon {
        fill: $red !important;
      }
    }
    .video {
      font-size: 28px;
    }
    .share {
      box-shadow: none !important;
      font-size: 48px;
      box-shadow: none;
      background: transparent !important;
      border-radius: 0 !important;
    }
  }
}

.callDisPopUp {
  .dropdown-menu {
    $background: rgba(#5e5e5e, 0.61);
    outline: none;
    border: 0;
    background: $background;
    top: initial !important;
    left: 50% !important;
    bottom: calc(100% + 20px);
    transform: translateX(-50%) !important;
    border-radius: 12px !important;
    padding: 30px;
    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-top: 15px solid $background;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-bottom: 0;
    }
    &.show {
      display: flex;
    }
    button {
      background: transparent;
      border: 0;
      line-height: 1;
      color: #fff;
      white-space: nowrap;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: 0.3s;
      margin: 0 8px;
      &:hover {
        opacity: 0.7;
      }
      img {
        margin-bottom: 16px;
      }
    }
  }
}

.screenShotViewWrapper {
  position: fixed;
  bottom: 0px;
  left: 0px;
  transition: all 0.3s ease 0s;
  z-index: 999;
  .image {
    max-width: 200px;
  }
  .btns {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: space-around;
    .btn-icon {
      background: $red;
      margin: 2px;
      border-radius: 100%;
      width: 26px;
      height: 26px;
      font-size: 12px;
      transition: 0.3s;
      color: #fff;
      &:hover {
        background: darken($red, 10%);
        &:active {
          background: darken($red, 20%);
        }
      }
      &.facebook {
        background: #4267b2;
        &:hover {
          background: darken(#4267b2, 10%);
        }
        &:active {
          background: darken(#4267b2, 20%);
        }
      }
    }
  }
}

.giphy-view {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  .light-theme & {
    width: calc(100% - 10px);
    margin-bottom: 23px;
  }
  .wrapper {
    background: #000;
    padding: 16px 8px;
    border-radius: 20px;
    position: relative;
    .isDisabled {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      background: #989898;
      opacity: 0.8;
      cursor: no-drop;
      border-radius: inherit;
    }
    .light-theme & {
      background: #f4f3f5;
    }
  }
  .smiles {
    display: flex;
    flex-wrap: wrap;
    max-height: 307px;
    overflow: auto;
    .smileItems {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
    .smileItem {
      width: 100%;
      padding-top: 100%;
      position: relative;
      cursor: pointer;
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.isCoverFit {
  object-fit: cover !important;
}

.btn-icon {
  &.video-mic {
    $boxShadow: inset 0 0 0 1px #5d5e5d;
    -moz-box-shadow: $boxShadow;
    -webkit-box-shadow: $boxShadow;
    box-shadow: $boxShadow;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    background: #fff;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 5;

    &.disable,
    &.disabled {
      svg {
        opacity: 0.7;
      }
      &::before {
        content: "";
        position: absolute;
        height: 3px;
        width: 100%;
        transform: rotate(-45deg) translate(-50%, -50%);
        border-radius: 10px;
        left: 50%;
        z-index: 1;
        top: 50%;
        transform-origin: 0 0;
      }
    }
    &.disable,
    &.disabled {
      &::before {
        background: #5d5e5d;
      }
    }
  }
  &.video-speaker {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 5;
  }
}
