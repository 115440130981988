.eventCalanderScreen {
  padding: 20px 0;
  background: #d5d5d5;
  @media (max-width: 1200px) {
    padding-top: 35px;
  }
  .wrapper {
    max-width: 900px;
    height: 100%;
    margin: 0 auto;
    padding: 0 15px;
    position: relative;
    .backBtn {
      white-space: nowrap;
      color: #fff;
      margin-bottom: 4px;
      font-size: 18px;
      display: block;
      position: absolute;
      @media (min-width: 1200px) {
        right: 100%;
      }
      @media (max-width: 1200px) {
        bottom: 100%;
      }
    }
  }
}

.eventCalcander {
  padding: 10px;
  background: #fff;
  box-shadow: 0 3px 6px 0 #8b8b8b;
  .rbc {
    &-event {
      font-size: 14px;
      background-color: $blue;
      &-content {
        // text-transform: capitalize;
      }
    }
    &-off-range-bg {
    }
    &-toolbar {
      &-label {
        text-align: left;
        font-weight: 500;
      }
    }
    &-header {
      border-bottom: 0;
      text-transform: uppercase;
      font-size: 13px;
    }
    &-date-cell {
      text-align: center;
      padding-right: 0;
      font-size: 14px;
    }
    &-btn-group {
      &:first-child {
        button {
          border-radius: 4px;
          &:nth-child(2),
          &:last-child {
            border: 0;
            font-size: 0;
            &::before {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              line-height: 1;
              font-size: 16px;
            }
          }
          &:nth-child(2) {
            &::before {
              content: "\f104";
            }
          }
          &:last-child {
            &::before {
              content: "\f105";
            }
          }
        }
      }
    }
  }
}
