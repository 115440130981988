button {
  outline: none !important;
}
.noBoxShadow {
  box-shadow: none !important;
}
.btn {
  border-radius: 100px;
  &-icon {
    background: transparent;
    border: 0;
    outline: 0 !important;
    &-important {
      background: transparent !important;
      border: 0 !important;
    }
  }
  &.shadow-none {
    box-shadow: none;
    outline: 0;
  }
  &.disabled,
  &:disabled {
    cursor: not-allowed;
  }
  &-secondary {
    &.disabled,
    &:disabled {
      background-color: #d9dbe0;
      border-color: #d9dbe0;
    }
  }
  &-primary {
    &.disabled,
    &:disabled {
      background-color: lighten($blue, $amount: 25%);
      border-color: lighten($blue, $amount: 25%);
    }
  }
}

.createBtn {
  font-size: 22px;
  font-weight: 500;
  padding: 5px 17px;
  margin: 0 8px;
}

.btb-icon {
  background: transparent;
  border: 0;
  outline: 0;
}

.imgBoxBtnWrapper {
  .imageBox {
    transition: 0.3s;
  }
  &:hover {
    .imageBox {
      box-shadow: 0 3px 9px 1px #8b8b8b;
      img {
      }
    }
  }
}

.adminTabBtn {
  text-align: center;
  margin-bottom: 40px;
  button {
    color: #d6d5d5;
    min-width: 170px;
    background: transparent;
    border: 0;
    border-bottom: 2px solid transparent;
    font-size: 18px;
    font-weight: 500;
    padding: 6px;
    &:hover {
      border-color: #d6d5d5;
    }
    &.active {
      color: $purple;
      border-color: $purple;
    }
  }
}
